/**
 * READ (3/10/15): Entity enumerable values SHOULD NO LONGER BE IN THIS FILE.
 *
 * They should be in their respective entity module.  For example:
 * ```
 * exports.Project = require('optly/modules/entity/project');
 * Project.enums.project_status.ACTIVE
 * ```
 */
/* eslint-disable import/no-commonjs */
const IntegrationEnums = require('optly/modules/entity/integration/enums');
const ProjectEnums = require('optly/modules/entity/project/enums');
const tr = require('optly/translate');

exports.AndroidLayoutConstants = {
  MATCH_PARENT: -1,
  WRAP_CONTENT: -2,
};

// these values correspond to window.optlyConfig.env.ENVIRONMENT values
exports.AppEnvironment = {
  DEV: 'local',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

exports.ProjectJavascriptCodeMirror = {
  BLANK_DEFAULT: '\n\n\n\n\n\n\n\n\n\n\n',
};

exports.ChangeHistoryTypeName = {
  CUSTOM_EVENT: 'custom event',
};

exports.deviceContainerIds = {
  EDITOR_DEVICE_CONTAINER: 'editor-device-container',
  GOALS_DIALOG_DEVICE_CONTAINER: 'goals-dialog-device-container',
};

exports.EmailLinkState = {
  NOT_SENT: tr('Send'),
  SENDING: tr('Sending...'),
  SENT: tr('Sent!'),
};

exports.EmailSDKInstructionsState = {
  NOT_SENT: tr('Send Email &amp; Continue'),
  SENDING: tr('Sending...'),
  SENT: tr('Sent!'),
};

exports.EventProperties = {
  NAME: 'n',
  TYPE: 'y',
  METADATA: 'o',
  REVENUE: 'r',
  TIMESTAMP: 't',
};

exports.ExperimentStatusType = {
  ARCHIVED: 'Archived',
  DELETED: 'Deleted',
  ERROR: 'Error',
  NOT_STARTED: 'Not started',
  PAUSED: 'Paused',
  PAUSING: 'Pausing...',
  RUNNING: 'Running',
  STARTING: 'Starting...',
};

exports.ExperimentStatusTypeDisplayText = {
  ARCHIVED: tr('Archived'),
  DELETED: tr('Deleted'),
  ERROR: tr('Error'),
  NOT_STARTED: tr('Not started'),
  PAUSED: tr('Paused'),
  PAUSING: tr('Pausing...'),
  RUNNING: tr('Running'),
  STARTING: tr('Starting...'),
};

/**
 * These are role-based permissions; you can find them in src/www/models/role.py.
 */
exports.Permissions = {
  ARCHIVE_EXPERIMENT: 'archive-experiment',
  BILLING: 'billing',
  CREATE_EXPERIMENT: 'create-experiment',
  CREATE_AUDIENCE: 'create-audience',
  CREATE_CUSTOM_ATTRIBUTE: 'create-dimension',
  CREATE_CROSS_PROJECT_METRICS: 'create-cross-project-metrics',
  CREATE_EXPERIMENT_GROUP: 'create-experiment-group',
  CREATE_PROJECT: 'create-project',
  CREATE_COMMIT: 'create-commit',
  CREATE_LAYER: 'create-layer',
  CREATE_LAYER_EXPERIMENT: 'create-layer-experiment',
  CREATE_LIVE_COMMIT_TAG: 'create-live-commit-tag',
  CREATE_METRICS: 'create-metrics',
  CREATE_SAR: 'create-sar',
  CREATE_SNIPPET: 'create-snippet',
  CREATE_TAG: 'create-tag',
  CREATE_USER_EVENT: 'create-user-event',
  CREATE_VIEW: 'create-view',
  DELETE_CUSTOM_ATTRIBUTE: 'delete-dimension',
  DELETE_CROSS_PROJECT_METRICS: 'delete-cross-project-metrics',
  DELETE_EXPERIMENT: 'delete-experiment',
  DELETE_USER_ACCOUNTS: 'delete-user-accounts',
  DELETE_EXPERIMENT_GROUP: 'delete-experiment-group',
  DELETE_LAYER: 'delete-layer',
  DELETE_LAYER_EXPERIMENT: 'delete-layer-experiment',
  DELETE_METRICS: 'delete-metrics',
  DELETE_SNIPPET: 'delete-snippet',
  DELETE_TAG: 'delete-tag',
  DELETE_USER_EVENT: 'delete-user-event',
  DELETE_VIEW: 'delete-view',
  UPDATE_CROSS_PROJECT_METRICS: 'update-cross-project-metrics',
  EDIT_PROJECT: 'edit-project',
  EDIT_PROJECT_INTEGRATION: 'edit-project-integration',
  EDIT_PROJECT_SHARING: 'edit-project-sharing',
  EDITOR_DEBUG: 'editor-debug',
  EXPORT_EVENTS: 'export events',
  INVITE: 'invite',
  MANAGE_BEARER_TOKEN: 'manage-bearer-token',
  MANAGE_FEATURE_FLAG: 'manage-feature-flag',
  MANAGE_MANIFEST: 'manage-manifest',
  MANAGE_USER_LIST: 'manage-user-list',
  MANAGE_OAUTH_CLIENT: 'manage-oauth-client',
  MANAGE_RECOMMENDER_SERVICE: 'manage-recommender-service',
  MANAGE_WEBHOOK: 'manage-webhook',
  MIGRATE_TO_FLAGS: 'migrate-to-flags',
  MODIFY_ACCOUNT_TOKENS: 'modify-account-tokens',
  MODIFY_CUSTOM_ATTRIBUTE: 'modify-dimension',
  MODIFY_PAUSED_EXPERIMENT: 'modify-paused-experiment',
  MODIFY_RUNNING_EXPERIMENT: 'modify-running-experiment',
  MODIFY_AUDIENCE: 'modify-audience',
  MODIFY_SHARE_TOKENS: 'modify-share-tokens',
  PAUSE_EXPERIMENT: 'pause-experiment',
  PREVIEW_DEBUG: 'preview-debug',
  READ_SAR: 'read-sar',
  RESET_RESULTS: 'reset-results',
  SNIPPET_BUNDLING_CONFIGURATION: 'snippet-bundling-configuration', // User permission to set the client_build_settings
  START_EXPERIMENT: 'start-experiment',
  UPDATE_ATLASSIAN_LINKS: 'update-atlassian-links',
  UPDATE_COMMIT: 'update-commit',
  UPDATE_EXPERIMENT_GROUP: 'update-experiment-group',
  UPDATE_LAYER: 'update-layer',
  UPDATE_LAYER_EXPERIMENT: 'update-layer-experiment',
  UPDATE_LAYER_EXPERIMENT_WITH_EXTENSION:
    'update-layer-experiment-with-extension',
  UPDATE_LIVE_COMMIT_TAG: 'update-live-commit-tag',
  UPDATE_METRICS: 'update-metrics',
  UPDATE_SNIPPET: 'update-snippet',
  UPDATE_TAG: 'update-tag',
  UPDATE_USER_ACCOUNTS: 'update-user-accounts',
  UPDATE_USER_EVENT: 'update-user-event',
  UPDATE_VIEW: 'update-view',
  USER_PERMISSIONS: 'user-permissions',
  VIEW_AUDIENCE: 'view-audience',
  VIEW_AUDIT_LOG: 'view-audit-log',
  VIEW_COLLABORATORS: 'view-collaborators',
  VIEW_COMMIT: 'view-commit',
  VIEW_LAYER: 'view-layer',
  VIEW_LAYER_EXPERIMENT: 'view-layer-experiment',
  VIEW_LIVE_COMMIT_TAG: 'view-live-commit-tag',
  VIEW_OAUTH_CLIENT: 'view-oauth-client',
  VIEW_RECOMMENDER_SERVICE: 'view-recommender-service',
  VIEW_SHARE_TOKENS: 'view-share-tokens',
  VIEW_SNIPPET: 'view-snippet',
  VIEW_TAG: 'view-tag',
  VIEW_USER_ACCOUNTS: 'view-user-accounts',
  VIEW_USER_EVENT: 'view-user-event',
  VIEW_USER_LIST: 'view-user-list',
  VIEW_VIEW: 'view-view',
  VUE_EDITOR_DRAWER: 'vue-editor-drawer',
};

/**
 * These are the features listed in src/www/models/feature.py.
 */
exports.Features = {
  AB_TESTING: 'ab-testing',
  AB_TESTING_V2: 'ab-testing-v2',
  ACCOUNT_TWO_FACTOR_AUTH: 'account-two-factor-auth',
  ACTIVITY_LOG: 'activity-log',
  ADAPTIVE_AUDIENCES: 'adaptive_audiences',
  AD_CAMPAIGN_TARGETING: 'ad-campaign-targeting',
  ADD_USER: 'add-user', // Seems like role but is in feature.py
  AI_WEB_COPY_VARIATIONS: 'ai_web_copy_variations_fg',
  ANALYTICS_EXTENSIONS: 'analytics-extensions',
  APPS_TAB: 'apps-tab',
  ATTACHED_REVENUE: 'attached-revenue',
  AUDIENCE_API_ONLY: 'audience-api-only',
  AUDIENCE_TARGETING: 'audience-targeting',
  AUDIT_LOG: 'audit-log',
  BANDITS: 'bandits',
  BANDITS_V2: 'bandits-v2',
  BEHAVIORAL_TARGETING: 'behavioral-targeting',
  BUCKETED_REVENUE: 'bucketed-revenue',
  CSHARP_SDK: 'csharp-sdk',
  CONCURRENT_EDITING: 'concurrent-editing',
  CONDITIONAL_ACTIVATION: 'conditional-activation',
  CONTINUOUS_OPTIMIZATION_DASHBOARD: 'continuous-optimization-dashboard',
  CROSS_PROJECT_DUPLICATION: 'cross-project-duplication',
  CROSS_PROJECT_METRICS: 'cross-project-metrics',
  CSP_SUPPORT: 'csp-support',
  CUSTOM_ATTRIBUTES: 'custom-attributes',
  CUSTOM_AUDIENCE_INTEGRATIONS: 'custom-audience-integrations',
  CUSTOM_SEGMENTS: 'custom-segments',
  DISABLE_EDITOR_IFRAME_PRELOAD: 'disable-editor-iframe-preload',
  DYNAMIC_CUSTOMER_PROFILE: 'dynamic-customer-profile',
  EDGE_EXPERIMENTS: 'edge_experiments',
  ENABLE_TEAMS_WORKFLOW: 'enable-teams-workflow', // Access to Product Management
  ENVIRONMENTS: 'environments',
  EVENT_EXTENSIONS: 'event-extensions',
  EXECUTIVE_SUMMARY: 'executive-summary',
  FEATURE_FLAGS: 'feature-flags',
  FEATURE_MANAGEMENT: 'feature-management',
  FEATURE_MANAGEMENT_VARIABLES: 'feature-management-variables',
  FEATURE_ROLLOUTS: 'feature-rollouts',
  FULL_STACK: 'full-stack',
  FULL_STACK_ADVANCED_SETTINGS: 'full-stack-advanced-settings',
  FULL_STACK_BOT_FILTERING: 'full-stack-bot-filtering',
  FULL_STACK_EVENTS: 'full-stack-events',
  FULL_STACK_EXPERIMENTS: 'full-stack-experiments',
  FULL_STACK_MULTIPLE_PROJECTS: 'full-stack-multiple-projects',
  FX_CHANGE_HISTORY_WEBHOOKS: 'fx-change-history-webhooks',
  NUMBER_OF_WEB_PROJECTS: 'number-of-web-projects',
  NUMBER_OF_FX_PROJECTS: 'number-of-fx-projects',
  GEOTARGETING: 'geotargeting', // Target by continent, country, region, or city.
  GEOFENCE_TARGETING: 'geofence-targeting', // Target by circular or polygonal geofence.
  GRANULAR_PERMISSIONS: 'granular-permissions', // Granular permissions for user roles
  IMAGE_CDN_HOST_PREFIX: 'image-cdn-host-prefix',
  IMPRESSION_METRICS: 'impression-metrics', // Ability to see total counts on the results page
  INTEGRATION_DASHBOARD: 'integration-dashboard',
  LAUNCH_EXPERIMENT: 'launch-experiment',
  LIST_ATTRIBUTE: 'list-attribute',
  LIST_TARGETING: 'list-targeting',
  REST_API: 'rest_api',
  M1_P13N: 'm1_p13n',
  MOBILE: 'mobile',
  MOBILE_AUDIENCES: 'mobile-audiences',
  MOBILE_P13N: 'mobile-p13n',
  MULTICHANNEL_EXPERIMENTS: 'multichannel-experiments',
  MULTIPAGE_TESTING: 'multipage-testing',
  MULTIVARIATE_TESTING: 'multivariate-testing',
  MUTEX: 'mutual-exclusivity',
  MVT_X: 'multivariate-testing-x',
  MVT_X_FULLSTACK: 'multivariate-testing-x-fullstack',
  MVT_X_PARTIAL_FACTORIAL: 'multivariate-testing-x-partial',
  OBSERVE_CHANGES_INDEFINITELY: 'observe-changes-indefinitely', // Feature flag to observe editor DOM changes indefinitely via mutation observers
  ONLINE_TICKETS: 'online-tickets',
  OPAL_COPILOT: 'opal_copilot_new',
  OUTLIER_FILTERING: 'outlier-filtering',
  OUTLIER_FILTERING_GA: 'outlier-filtering-ga',
  OTT: 'over-the-top',
  PERSONALIZATION: 'personalization',
  PLUGINS: 'plugins',
  PREMIUM_DEFAULT_SEGMENTS: 'premium-default-segments',
  PROJECT_JS: 'project-js',
  PUBLISH_LAYER_ONLY: 'publish-layer-only', // Ability to publish campaign settings only (see WEB-1485)
  RECOMMENDER: 'recommender',
  REDIRECTS_V2: 'redirects-v2',
  REVENUE_REPORT: 'revenue-report', // Ability to see revenue report on the results page
  SCHEDULING: 'scheduling',
  SECURE_ENVIRONMENTS: 'secure-environments',
  SERVER_SIDE_TESTING: 'server-side-testing',
  SEGMENTED_RESULTS: 'segmented-results',
  SELECT_CONTAINER: 'select-container',
  SEQUENTIAL_STATISTICS: 'sequential-statistics',
  SHAREABLE_PROJECTS: 'shareable-projects',
  SNIPPETS: 'snippets', // Feature flag to allow creating new snippets which can contain data from more than one project
  SOURCE_TARGETING: 'source-targeting',
  STATS_ACCELERATOR: 'stats-accelerator',
  TARGETED_ROLLOUTS: 'targeted-rollouts',
  TIME_AND_DAY_TARGETING: 'time-and-day-targeting',
  USE_OPTIMIZELY_TEMPLATES: 'use_optimizely_templates',
  USER_TWO_FACTOR_AUTH: 'user-two-factor-auth',
  URL_TARGETING: 'url-targeting',
  UNLIMITED_CUSTOM_ATTRIBUTES: 'unlimited-custom-attributes',
  VIEW_DASHBOARD2: 'view-dashboard2',
  VIEW_DASHBOARD_X: 'view_dashboard_x',
  WIDGETS: 'widgets',
  STAT_SIG_NOTIFICATION_PREFERENCE: 'Stat_Sig_notification_preference',

  // NB: As of 8/9/2019, these items do not have a backend equivalent in feature.py's EnumeratedFeatures.
  // These items were added to support feature gating for Edge UX.
  // We probably need to add them to support backend validations.
  // @TODO https://optimizely.atlassian.net/browse/CJS-3193
  LAYER_INTEGRATIONS: 'layer-integrations',
  INSECURE_PREVIEW: 'insecure-preview',
  PREVIEW: 'preview',
  EXPERIMENT_TRAFFIC_ALLOCATION: 'experiment-traffic-allocation',
  ASYNC_CHANGES: 'async-changes',
  CHANGE_DEPENDENCIES: 'change-dependencies',
  VISUAL_TAGS: 'visual-tags',
  ADVANCED_PAGE_SETTINGS: 'advanced-page-settings',
  ACTIVATION_MODE_MANUAL: 'activation-mode-manual',
  ACTIVATION_MODE_POLLING: 'activation-mode-polling',
  ACTIVATION_MODE_CALLBACK: 'activation-mode-callback',
  ACTIVATION_MODE_URL_CHANGED: 'activation-mode-url-changed',
  ACTIVATION_MODE_DOM_CHANGED: 'activation-mode-dom-changed',
  VIEW_CONDITION_CUSTOM_CODE: 'view-condition-custom-code',
  VIEW_CONDITION_ELEMENT_PRESENT: 'view-condition-element-present',
  WEB_STICKY_BUCKETING: 'web-sticky-bucketing',
};

/**
 * This blacklist contains a list of features, and the project delivery mode
 * which they are blacklisted. For example, as of 8/14/19, customers can't use
 * Analytics Extensions with Edge projects.
 */
exports.DeliveryModeBlacklist = {
  // These items already exist in enums.Features.
  [exports.Features.ADAPTIVE_AUDIENCES]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.ANALYTICS_EXTENSIONS]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.BANDITS_V2]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.STATS_ACCELERATOR]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.CUSTOM_ATTRIBUTES]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.CUSTOM_AUDIENCE_INTEGRATIONS]: [
    ProjectEnums.delivery_modes.EDGE,
  ],
  [exports.Features.CUSTOM_SEGMENTS]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.DYNAMIC_CUSTOMER_PROFILE]: [
    ProjectEnums.delivery_modes.EDGE,
  ],
  [exports.Features.BEHAVIORAL_TARGETING]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.LIST_ATTRIBUTE]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.MVT_X]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.PERSONALIZATION]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.RECOMMENDER]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.SHAREABLE_PROJECTS]: [ProjectEnums.delivery_modes.EDGE],

  // These items were added for Edge; they do not have a backend equiv in feature.py
  // See note in exports.Features.
  [exports.Features.ASYNC_CHANGES]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.CHANGE_DEPENDENCIES]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.INSECURE_PREVIEW]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.LAYER_INTEGRATIONS]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.PREVIEW]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.WEB_STICKY_BUCKETING]: [ProjectEnums.delivery_modes.EDGE],
  [exports.Features.VISUAL_TAGS]: [ProjectEnums.delivery_modes.EDGE],

  // start will NEVER be supported in edge
  [exports.Features.ACTIVATION_MODE_POLLING]: [
    ProjectEnums.delivery_modes.EDGE,
  ],
  [exports.Features.ACTIVATION_MODE_CALLBACK]: [
    ProjectEnums.delivery_modes.EDGE,
  ],
};

exports.GoalType = {
  CLICK: 0,
  CUSTOM_EVENT: 1,
  ENGAGEMENT: 2,
  PAGEVIEW: 3,
  REVENUE: 4,
  MOBILE_TAP_EVENT_TYPE: 5,
  MOBILE_VIEW_EVENT_TYPE: 6,
  MOBILE_SESSION_GOAL: 7,
  MOBILE_SESSION_LENGTH_GOAL: 8,
  MOBILE_NUM_SESSION_GOAL: 9,
};

exports.builtInGoalTypes = [
  exports.GoalType.ENGAGEMENT,
  exports.GoalType.REVENUE,
  exports.GoalType.MOBILE_SESSION_GOAL,
  exports.GoalType.MOBILE_SESSION_LENGTH_GOAL,
  exports.GoalType.MOBILE_NUM_SESSION_GOAL,
];

exports.GoalTypeName = {
  CLICK: tr('Click'),
  CUSTOM_EVENT: tr('Custom Event'),
  ENGAGEMENT: tr('Built-in'),
  PAGEVIEW: tr('Pageview'),
  REVENUE: tr('Built-in'),
  MOBILE_TAP_EVENT_TYPE: tr('Tap'),
  MOBILE_VIEW_EVENT_TYPE: tr('View'),
  MOBILE_SESSION_GOAL: tr('Built-in'),
  MOBILE_SESSION_LENGTH_GOAL: tr('Built-in'),
  MOBILE_NUM_SESSION_GOAL: tr('Built-in'),
};

exports.HttpStatusCodes = {
  GONE: 410,
};

exports.IntegrationCategoryTypes = IntegrationEnums.categories;

// List of integration category types.
// This list is not used anywhere but we need to keep it to extract English values from tr() calls
// to translate them later.
exports.IntegrationCategoryTypesDisplayText = {
  ALL: tr('All'),
  ANALYTICS: tr('Analytics'),
  AUDIENCES: tr('Audiences'),
  CONTENT_MANAGEMENT: tr('Content Management'),
  CALL_TRACKING: tr('Call Tracking'),
  HEATMAP: tr('Heatmap'),
  PRODUCTIVITY: tr('Productivity'),
};

exports.ProjectPlatforms = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
  CUSTOM: 'custom',
};

exports.ProjectSnippets = {
  START: 'start',
  START_WITH_ENABLE_EDITOR: 'start-enable-editor',
};

/**
 * @enum {string}
 */
exports.ProjectPlatformsDisplayText = {
  android: 'Android',
  ios: 'iOS',
  web: tr('Web'),
};

exports.ProjectPlatformsMobile = [
  exports.ProjectPlatforms.ANDROID,
  exports.ProjectPlatforms.IOS,
];

/**
 * Enum for types in accounts.py.
 *
 *  @enum {string}
 *  @deprecated see modules/entity/project/enums.js
 */
exports.ProjectStatusType = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
};

/**
 *  @enum {string}
 *  @deprecated see modules/entity/project/enums.js
 */
exports.ProjectPlatformType = {
  ANDROID: 'android',
  CUSTOM: 'custom',
  IOS: 'ios',
  WEB: 'web',
};

exports.MobilePlatformTypes = [
  exports.ProjectPlatformType.ANDROID,
  exports.ProjectPlatformType.IOS,
];

exports.CodeBlockConstants = {
  CODEBLOCK_DEFAULT_VALUE_STRING:
    '__Optimizely_codeblock_default_value_placeholder__',
};

exports.UIInterfaceOrientation = {
  PORTRAIT: 1, // Device oriented vertically, home button on the bottom
  PORTRAIT_UPSIDE_DOWN: 2, // Device oriented vertically, home button on the top
  LANDSCAPE_LEFT: 3, // Device oriented horizontally, home button on the right
  LANDSCAPE_RIGHT: 4, // Device oriented horizontally, home button on the left
};

exports.UIInterfaceOrientationAndroid = {
  PORTRAIT: 1, // Device oriented vertically, home button on the bottom
  LANDSCAPE: 2, // Device oriented horizontally, home button on the right
};

exports.DimensionStatusType = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

exports.urlMatchTypes = {
  EXACT: 1,
  REGEX: 2,
  SIMPLE: 3,
  SUBSTRING: 4,
};

exports.urlMatchTypeNames = {
  1: 'Exact',
  2: 'Regular expression',
  3: 'Simple',
  4: 'Substring',
};

// List of all possible user roles.
// This list is not used anywhere but we need to keep it to extract English values from tr() calls
// to translate them later.
exports.userRoles = {
  'Gae Administrator': tr('Gae Administrator'),
  Administrator: tr('Administrator'),
  'Project Owner': tr('Project Owner'),
  Editor: tr('Editor'),
  Viewer: tr('Viewer'),
  Universal: tr('Universal'),
  'Object Owner': tr('Object Owner'),
  'Shared Token Accessor': tr('Shared Token Accessor'),
  'Project Creator': tr('Project Creator'),
  User: tr('User'),
};

/**
 * Enum for Google Analytics categories used in trackEventGA in analytics.js.
 * Actions correspond to behaviors on features. Common ones should be enumerated here.
 * https://developers.google.com/analytics/devguides/collection/gajs/eventTrackerGuide#Actions
 * @enum {string}
 */
exports.TrackingEventAction = {
  ADD: 'add',
  APPLY: 'apply',
  CANCEL: 'cancel',
  CLICK: 'click',
  COLLAPSE: 'collapse',
  CREATE: 'create',
  EDIT: 'edit',
  EXPAND: 'expand',
  OPEN: 'open',
  REMOVE: 'remove',
  REMOVE_DUPLICATE_LINE: 'removeDuplicateLine',
  SHOW_DIALOG: 'showDialog',
  TOGGLE: 'toggle',
};

/**
 * Enum for Google Analytics categories used in trackEventGA in analytics.js.
 * Categories correspond to distinct features.
 * https://developers.google.com/analytics/devguides/collection/gajs/eventTrackerGuide#Categories
 * @enum {string}
 */
exports.TrackingEventCategory = {
  PREVIEW: 'Preview',
  AUDIENCES: 'Audiences',
  RESULTS: 'Results',
  WEB_EDITOR: 'Web Editor',
};

exports.MessageType = {
  APPEND_AND_SELECT: 'append and select',
  AUTO_SELECT_ELEMENT: 'auto-select element',
  BRING_TO_FRONT: 'bring to front',
  CANCEL_CHANGE: 'cancel change',
  CHANGE_ELEMENT: 'change element',
  CHECK_PAGE_OVERFLOW: 'check page overflow',
  CLICK: 'click',
  COUNT_ELEMENTS_MATCHING_SELECTORS: 'count elements matching selectors',
  CUSTOM_TAGS: 'custom tags on page',
  DESELECT_ELEMENT: 'deselect element',
  ELEMENT_BOUNDS_CHANGED: 'element bounds changed',
  ELEMENT_CHANGED: 'element changed',
  ELEMENT_DESELECTED: 'element deselected',
  ELEMENT_SELECTED: 'element selected',
  EVALUATE: 'evaluate',
  EVALUATION_COMPLETED: 'evaluation completed',
  EVALUATION_ERROR: 'evaluation error',
  HELLO: 'hello',
  HIDE_CHANGER: 'hide changer',
  HIGHLIGHT_TRACKED_ELEMENTS: 'highlight tracked elements',
  INSERT_TEXT: 'insert text',
  KEY_PRESSED: 'key pressed',
  PREPARE_CHANGE: 'prepare change',
  PREPARE_MOVE_AND_RESIZE: 'prepare move and resize',
  PREPARE_REARRANGE: 'prepare rearrange',
  READY: 'ready',
  REDIRECT_NOTIFICATION: 'redirect notification',
  SCROLL_TO_ELEMENT: 'scroll to element',
  SELECT_ELEMENT: 'select element',
  SET_AT_INTERNET_DETAILS: 'set at internet details',
  SET_CLICK_GOALS: 'set click goals',
  SET_SELECTABLE: 'set selectable',
  SHOW_CHANGER: 'show changer',
  STYLES_FOR_ELEMENT: 'styles for element',
  SURROUND_CONTAINER: 'surround container',
  SURROUND_ELEMENTS: 'surround elements',
  UNSURROUND_CONTAINER: 'unsurround container',
  UNSURROUND_ELEMENTS: 'unsurround elements',
  WHICH_SNIPPET: 'which snippet',
  // only used for preview... not for edit
  PREVIEW_SET_IFRAME_HEIGHT: 'PREVIEW_SET_IFRAME_HEIGHT',
  PREVIEW_GET_CLIENT_DATA: 'PREVIEW_GET_CLIENT_DATA',
  PREVIEW_GET_CLIENT_DATA_ASYNC: 'PREVIEW_GET_CLIENT_DATA_ASYNC',
  PREVIEW_ACTIVATE_EXPERIMENT: 'PREVIEW_ACTIVATE_EXPERIMENT',
  PREVIEW_GET_LOG: 'PREVIEW_GET_LOG',
  PREVIEW_RELOAD: 'PREVIEW_RELOAD',
  PREVIEW_REMOVE_OVERLAY: 'PREVIEW_HIDE_OVERLAY',
  PREVIEW_MODEL_PERSISTOR_APPLY_EXPERIMENT_STATE:
    'PREVIEW_MODEL_PERSISTOR_APPLY_EXPERIMENT_STATE',
  PREVIEW_MODEL_PERSISTOR_GET_STATE: 'PREVIEW_MODEL_PERSISTOR_GET_STATE',
  PREVIEW_MODEL_PERSISTOR_GET_STATE_RESPONSE:
    'PREVIEW_MODEL_PERSISTOR_GET_STATE_RESPONSE',
  PREVIEW_MODEL_PERSISTOR_PERSIST_STATE:
    'PREVIEW_MODEL_PERSISTOR_PERSIST_STATE',
  PREVIEW_MODEL_PERSISTOR_PERSIST_STATE_RESPONSE:
    'PREVIEW_MODEL_PERSISTOR_PERSIST_STATE_RESPONSE',
};

exports.ZIndex = {
  CODE_ASSISTANT_TOGGLE: 100,
  ACTIVATION_MODE_DROPDOWN: 5,
  FILTER_STATUS_DROPDOWN: 11,
};
